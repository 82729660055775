import Globals from "@/constants/Globals";
import { useMemo } from "react";

export default function Guess({ rank, word, lastGuess, idx }) {
  const color = useMemo(() => {
    if (rank <= 1) {
      return "guess-blue";
    }
    if (rank <= 500) {
      return "guess-green";
    }
    if (rank <= 1350) {
      return "guess-yellow";
    }

    return "guess-red";
  }, [rank]);

  const percent = useMemo(() => {
    if (rank <= 1) {
      return 100;
    }
    if (rank > Globals.MinWords) {
      return 5;
    }
    const position = Globals.MinWords - rank;

    if (position < Globals.MinWords) {
      if (rank <= 75 && rank > 10) {
        return Math.round(((Globals.MinWords - 75) / Globals.MinWords) * 100);
      }
      return Math.max(Math.round((position / Globals.MinWords) * 100), 5);
    }
    return 5;
  }, [rank]);
  const isLastGuess = lastGuess && lastGuess.word === word
  const className = isLastGuess ? 'guess-container-active': 'guess-container';
  
  if(isLastGuess && idx === 0 ) {
    return null
  }

  return (
    <div
      className={className}
      style={{
        marginTop: 10,

        display: "flex",
        alignSelf: "stretch",
        marginLeft: 25,
        marginRight: 25,
        borderRadius: 10,
        marginBottom: 10,
        borderWidth: 2,
        overflow: "hidden",
        padding: 4,
        maxWidth: 500,
        position: "relative",
      }}
    >
      <div
        className={color}
        style={{
          overflow: "hidden",
          height: 42,
          // flex: 1,
          width: `${percent}%`,
          borderRadius: 6,
        }}
      ></div>
      <div
        className='primary-text'
        style={{
          position: "absolute",
          fontFamily: "Noto Sans",
          fontWeight: 700,
          left: 0,
          top: "21%",
          fontSize: 20,
          marginLeft: 15,
        }}
      >
        {word}
      </div>
      <div
        className='primary-text'
        style={{
          position: "absolute",
          fontFamily: "Noto Sans",
          fontWeight: 700,
          right: 0,
          top: "21%",
          fontSize: 20,
          marginRight: 15,
        }}
      >
        {rank}
      </div>
    </div>
  );
}
