import Globals, { MostCommonWords } from "@/constants/Globals";
import { HintMap } from "@/constants/HintMap";
import { Spinner } from "@chakra-ui/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import API from "../services/API";
import { GlobalContext } from "./GlobalContext";

const initialState = {
  isGuessing: false,
  lastGuess: {
    word: "",
    rank: 0,
  },
  guesses: [],
  currentGame: 0,
  currentError: "",
  lastHintUsed: undefined,
  setGuesses: () => {},
  setIsGuessing: () => {},
  setLastGuess: () => {},
  setCurrentError: () => {},
  startNewGame: () => {},
  addGuess: () => {},
  getHint: () => {}
}

export const DailyGameContext = React.createContext(initialState);

export const DailyGameContextProvider = (props) => {
  const [lastGuess, setLastGuess] = useState({
    word: "",
    rank: 0,
  });

  const [isGuessing, setIsGuessing] = useState(false);
  const [guesses, setGuesses] = useState([]);
  const [lastHintUsed, setLastHintUsed] = useState(undefined);
  const { numberOfCurrentDailyGames } = useContext(GlobalContext);
  const [currentGame, setCurrentGame] = useState(numberOfCurrentDailyGames);
  const [currentError, setCurrentError] = useState("");

  const clearState = useCallback(() => {
    setIsGuessing(initialState.isGuessing)
    setLastGuess(initialState.lastGuess)
    setGuesses(initialState.guesses)
    setCurrentGame(initialState.currentGame)
    setCurrentError(initialState.currentError)
    setLastHintUsed(initialState.lastHintUsed)
  }, [])

  const startNewGame = useCallback((newGameId) => {
    clearState()
    setCurrentGame(newGameId)
  }, [])

  const addGuess = useCallback(
    (newGuess) => {
      setLastGuess(newGuess);
      const alreadyGussed = {};
      const newGusses = guesses.filter((f) => {
        if (!alreadyGussed[f.word]) {
          alreadyGussed[f.word] = true;
          return true;
        }
        return false;
      });
      if (!alreadyGussed[newGuess.word]) {
        newGusses.push(newGuess);
      }
      newGusses.sort((a, b) => {
        return a.rank - b.rank;
      });
      setGuesses(newGusses);
    },
    [guesses, setGuesses]
  );

  const sendGuess = useCallback(
    (word) => {
      if (!isGuessing) {
        if (MostCommonWords[word]) {
          setCurrentError(Globals.WordTooCommon);
          return;
        }
        setIsGuessing(true);
        API.guess({
          guess: word,
          game: currentGame,
        })
          .then((response) => {
            setIsGuessing(false);
            if (response.word) {
              addGuess(response);
            } else {
              setCurrentError(Globals.CouldNotFindWord);
            }
          })
          .catch((e) => {
            setCurrentError(Globals.UnkownError);
            setIsGuessing(false);
          });
      }
    },
    [isGuessing, currentGame, addGuess, guesses]
  );

  const updateHint = useCallback(
    () => {
      if(!lastHintUsed) {
        setLastHintUsed(Globals.FirstHint)
      } else {
        setLastHintUsed(HintMap[lastHintUsed])
      }
    },
    [lastHintUsed, setLastHintUsed]
  );

  const getHint = useCallback(() => {
    if (!isGuessing) {
      setIsGuessing(true);
      API.getHint({
        previousHint: lastHintUsed,
        game: currentGame,
      })
        .then((response) => {
          setIsGuessing(false);
          if (response.hint) {
            sendGuess(response.hint)
            updateHint()
          } else {
            setCurrentError(Globals.NoHintsLeft);
          }
        })
        .catch((e) => {
          setCurrentError(Globals.UnkownError);
          setIsGuessing(false);
        });
    }
  }, [isGuessing, guesses, lastHintUsed, currentGame, setGuesses, addGuess]);

  return (
    <DailyGameContext.Provider
      value={{
        lastGuess,
        isGuessing,
        guesses,
        currentGame,
        currentError,
        setLastGuess,
        setGuesses,
        setIsGuessing,
        setCurrentGame,
        setCurrentError,
        addGuess,
        getHint, 
        sendGuess,
        startNewGame,
      }}
    >
      {props.children}
    </DailyGameContext.Provider>
  );
};
