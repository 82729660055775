export const HintMap = {
  300: 150,
  150: 100,
  100: 75,
  75: 50,
  50: 25,
  25: 10,
  10: 8,
  8: 7,
  7: 5,
  5: 4,
  4: 3,
  3: 2,
  2: 1,
};
