export default {
  WinnerRank: 1,
  FirstHint: 300,
  MinWords: 2000,
  CouldNotFindWord: "Hmm, I didn't understand this word.",
  WordTooCommon: "This word is too common, try something else.",
  UnkownError: "Something went wrong, please try again.",
  NoHintsLeft: "No hints left.",
};

export const MostCommonWords = {
  the: true,
  at: true,
  there: true,
  some: true,
  my: true,
  of: true,
  be: true,
  use: true,
  than: true,
  and: true,
  this: true,
  an: true,
  would: true,
  first: true,
  a: true,
  have: true,
  each: true,
  to: true,
  from: true,
  which: true,
  been: true,
  in: true,
  or: true,
  she: true,
  is: true,
  one: true,
  do: true,
  into: true,
  who: true,
  you: true,
  had: true,
  how: true,
  that: true,
  by: true,
  their: true,
  has: true,
  its: true,
  it: true,
  if: true,
  now: true,
  he: true,
  but: true,
  was: true,
  not: true,
  more: true,
  for: true,
  what: true,
  other: true,
  on: true,
  all: true,
  about: true,
  go: true,
  are: true,
  were: true,
  did: true,
  as: true,
  we: true,
  get: true,
  with: true,
  when: true,
  then: true,
  no: true,
  your: true,
  them: true,
  way: true,
  made: true,
  they: true,
  can: true,
  these: true,
  could: true,
  may: true,
  i: true,
  am: true,
  hey: true,
  said: true,
  so: true,
};
