import Typewriter from 'typewriter-effect';

export default function LoadingGuess() {
  return (
    <div
      className='primary-text'
      style={{
        marginLeft: 30,
        marginRight: 30,
        fontSize: 16,
        fontWeight: 600,
        marginTop: 10,
      }}
    >
    {/* Guessing... */}
      <Typewriter
        options={{
          strings: ["Guessing...", "Calculating..."],
          autoStart: true,
          loop: true,
          delay: 0

        }}
      />
    </div>
  );
}
